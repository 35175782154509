import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/OrderItemsElementStyle'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function StocksImageItemComponent(props) {
    const classes = useStyles();
    const [stockImagesQuantity, setStockImagesQuantity] = React.useState(0)
    const [oldStockImagesQuantity, setOldStockImagesQuantity] = React.useState(0)
    const componentConstants = {
        ...props.componentConstants
      }
      const componentMethods = {
          ...props.componentMethods,
      }

      const postStocksImageAction = () => {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_IMAGES_STOCKS,{
            imagesStocksVariantsID: props.element.variantsID,
            imagesStocksQuantity: stockImagesQuantity
        },'POST',null, (data) => {
            componentMethods.addError(componentConstants.language.LANG_STOCK_ADDED)
            componentMethods.refreshSearch()
        })
      }
return (<TableRow>
            <TableCell>
                {props.element.variantsID}
            </TableCell>
            <TableCell>
                <a target="_blank" rel="noreferrer" href={Settings.API_URL + Settings.API_ENDPOINT_FILE_FULL + '?path=' + props.element.imagesPath + '&accountToken=' + componentMethods.getLocalStorage("user").tokensHash}>
                    <img style={{maxWidth: 150, maxHeight: 150}} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath} />
                </a>
            </TableCell>
            <TableCell>{props.element.variantsWidth} x {props.element.variantsHeight}</TableCell>
            <TableCell>
                <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                    <Input
                        onChange={(e) => {setStockImagesQuantity(e.target.value)}}
                        endAdornment={<InputAdornment position="end">{componentConstants.language.LANG_ADD_QUANTITY}</InputAdornment>}
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                        value={stockImagesQuantity}
                    />
                    <FormHelperText id="standard-weight-helper-text">{componentConstants.language.LANG_QUANTITY} </FormHelperText>
                </FormControl>
                {props.element.imagesStocksQuantity}
            </TableCell>
            <TableCell>
                <Button className={classes.button} color="primary" onClick={postStocksImageAction} fullWidth component="div" variant="contained">
                    {componentMethods.getManagerState().language.LANG_UPDATE_ORDERS_ITEMS_QUANTITY}
                </Button>
            </TableCell>
        </TableRow>
  );
}
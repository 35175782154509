import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/OrderItemsElementStyle'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function StocksItemComponent(props) {
    const classes = useStyles();
    const [isLoadedIsAffiliate, setIsLoadedAffiliate] = React.useState(false)
    const [isAffiliate, setIsAffiliate] = React.useState(false)
    const [isLoadedPhotographer, setIsLoadedPhotographer] = React.useState(false)
    const [isPhotographer, setIsPhotographer] = React.useState(false)
    const [affiliatesPercentage, setAffiliatesPercentage] = React.useState(0)
    const [photographerReward, setPhotographerReward] = React.useState(0)
    const [userAccessLevels, setUserAccessLevels] = React.useState([])
    const [loadedUserAccessLevels, setLoadedUserAccessLevels] = React.useState(false)
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
      const componentProps = {
          ...props.componentProps
      }

      const getUsersIsAffiliate = () => {
        setIsLoadedAffiliate(false)
        setIsAffiliate(false)
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_AFFILIATES_IS_AFFILIATE + props.element.usersID,{},'GET',null, (data) => {
            setIsLoadedAffiliate(true)
            setIsAffiliate(data.items)
        })
      }

      const getUserIsPhotographer = () => {
        setIsLoadedPhotographer(false)
        setIsPhotographer(false)
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_PHOTOGRAPHER_IS_PHOTOGRAPHER + props.element.usersID,{},'GET',null, (data) => {
            setIsLoadedPhotographer(true)
            setIsPhotographer(data.items)
        })
      }

      const getUserAccessLevelsAction = () => {

        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_USERS_ACCESS_LEVELS + props.element.usersID,{},'GET',null, (data) => {
            setUserAccessLevels(data.items)
            setLoadedUserAccessLevels(true)
        })
      }
      const hasAccessLevel = (accessLevelsID) => {
        for (let i in userAccessLevels)
        {
            if (parseInt(accessLevelsID) === parseInt(userAccessLevels[i].usersAccessLevelsAccessLevelsID))
                return true
        }

        return false
      }

      const getUsersAccessLevel = (accessLevelsID) => {
        for (let i in userAccessLevels)
        {
            if (parseInt(accessLevelsID) === parseInt(userAccessLevels[i].usersAccessLevelsAccessLevelsID))
                return userAccessLevels[i]
        }

        return false
      }
      const postAffiliate = () => {

        let postObject = {
            affiliatesUsersID: props.element.usersID,
            affiliatesPercentage: affiliatesPercentage
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_AFFILIATES,postObject,'POST',null, (data) => {
            getUsersIsAffiliate()
        })
      }

      const postUsersAccessLevels = (accessLevelsID) => {

        let postObject = {
            usersAccessLevelsUsersID: props.element.usersID,
            usersAccessLevelsAccessLevelsID: accessLevelsID
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_ACCESS_LEVELS,postObject,'POST',null, (data) => {
            getUserAccessLevelsAction()
        })
      }

      const postPhotographer = () => {

        let postObject = {
            usersAccessLevelsUsersID: props.element.usersID,
            usersRewardsAmount: photographerReward
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_PHOTOGRAPHER,postObject,'POST',null, (data) => {
            getUserIsPhotographer()
        })
      }
      const deleteUsersAccessLevelsAction = (usersAccessLevelsID) => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADMIN_ACCESS_LEVELS + '/' + usersAccessLevelsID,{},'DELETE',null, (data) => {
                getUserAccessLevelsAction()
            })
      }

      const deleteAffiliate = () => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_AFFILIATES + '/' + isAffiliate[0].affiliatesID,{},'DELETE',null, (data) => {
                getUsersIsAffiliate()
            })
      }

      useEffect(() => {
            getUsersIsAffiliate()
            getUserIsPhotographer()
            getUserAccessLevelsAction()
        }, []);
return (<TableRow>
            <TableCell>
                {props.element.usersID}
            </TableCell>
            <TableCell>
                {props.element.usersEmail}
            </TableCell>
            <TableCell>
                {props.element.usersFirstName}
            </TableCell>
            <TableCell>
                {props.element.usersLastName}
            </TableCell>
            <TableCell>
                {props.element.usersDateOfBirth}
            </TableCell>
            <TableCell>
                {props.element.usersLastLogin}
            </TableCell>
            <TableCell>
                {componentConstants.language[Settings.SETTINGS_USERS_SEX_ID[props.element.usersUsersSexID]]}
            </TableCell>
            <TableCell>
                {
                    isLoadedIsAffiliate && isAffiliate.length > 0 ?
                    <React.Fragment>
                        {isAffiliate[0].affiliatesPercentage}%
                        <Button onClick={deleteAffiliate}>{componentConstants.language.LANG_DELETE_AFFILIATE}</Button>
                    </React.Fragment>
                    :
                    null
                }
                {
                    isLoadedIsAffiliate && isAffiliate.length === 0 ?
                    <React.Fragment>
                        <Button onClick={postAffiliate} color="secondary" variant="contained">{componentConstants.language.LANG_MAKE_AFFILIATE}</Button>
                        <TextField fullWidth={true} value={affiliatesPercentage} onChange={(e)=> {setAffiliatesPercentage(e.target.value)}} label={componentConstants.language.LANG_AFFILIATES_PERCENTAGE} />
                    </React.Fragment>
                    :
                        null
                }
                {
                    !isLoadedIsAffiliate ?
                        <LoadingComponent />
                        :
                        null
                }
            </TableCell>
            <TableCell>
                {
                    isLoadedPhotographer && isPhotographer.length > 0 ?
                    <CheckBoxIcon />
                    :
                    null
                }
                {
                    isLoadedPhotographer && isPhotographer.length === 0 ?
                    <React.Fragment>
                        <Button onClick={postPhotographer} color="secondary" variant="contained">{componentConstants.language.LANG_MAKE_PHOTOGRAPHER}</Button>
                        <TextField fullWidth={true} value={photographerReward} onChange={(e)=> {setPhotographerReward(e.target.value)}} label={componentConstants.language.LANG_PHOTOGRAPHER_REWARD} />
                    </React.Fragment>
                    :
                        null
                }
                {
                    !isLoadedIsAffiliate ?
                        <LoadingComponent />
                        :
                        null
                }
            </TableCell>
            <TableCell>
                {
                    !componentMethods.getManagerState().loadingAdminAccessLevels && loadedUserAccessLevels ?
                    <React.Fragment>
                        {componentMethods.getManagerState().adminAccessLevels.map((el, idx) => {
                            return <div>
                                        {hasAccessLevel(el.accessLevelsID) ?
                                            <IconButton onClick={() => {deleteUsersAccessLevelsAction(getUsersAccessLevel(el.accessLevelsID).usersAccessLevelsID)}}><CheckBoxIcon /></IconButton>
                                            :
                                            <IconButton onClick={() => {postUsersAccessLevels(el.accessLevelsID)}}><ClearIcon /></IconButton>
                                        }
                                        {el.accessLevelsName}
                                    </div>
                        })}
                    </React.Fragment>
                    :
                    <LoadingComponent />
                }
            </TableCell>
        </TableRow>
  );
}
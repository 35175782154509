import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/OrderItemsElementStyle'
import Settings from '../../../Settings/Settings'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function OrdersItemsComponent(props) {
    const classes = useStyles();
    const [quantity, setQuantity] = React.useState(props.element.ordersItemsQuantity)
    const [confirmDelete, setConmfirmDelete] = React.useState(false)
    useEffect(() => {
        }, []);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods
      }

    const handleChangeQuantity = (event) => {
        setQuantity(parseInt(event.target.value))
    }

      const putOrderItemAction = () => {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ORDER_ITEMS + props.element.ordersItemsID,{
            ordersItemsQuantity: quantity
        },'PUT',null, (data) => {
            componentMethods.addError(componentConstants.language.LANG_ORDER_UPDATED)
        })
      }


      const deleteOrdersItemsAction = () => {
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ORDER_ITEMS + props.element.ordersItemsID,{},'DELETE',null, (data) => {
            componentMethods.addError(componentConstants.language.LANG_ORDER_UPDATED)
            componentMethods.getOrderItemsAction()
            setConmfirmDelete(false)
        })
      }
        return (<TableRow>
            <TableCell>
                {props.element.variantsID} <br />
                    <a target="_blank" rel="noreferrer" href={Settings.API_URL + Settings.API_ENDPOINT_FILE_FULL + '?path=' + props.element.imagesPath + '&accountToken=' + componentMethods.getLocalStorage("user").tokensHash}>
                        <img alt="" className={classes.img} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath } />
                    </a>    
            </TableCell>
            <TableCell>
                 <FormControl className={classes.formControl} fullWidth={true}>
                    <InputLabel>{componentConstants.language.LANG_QUANTITY}</InputLabel>
                    <Select
                    value={quantity}
                    onChange={handleChangeQuantity}
                    >
                    {[1,2,3,4,5,6,7,8,9].map((el, index) => {
                    return <MenuItem key={index} value={el}>{el}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </TableCell> 
            <TableCell>
                {componentConstants.language[props.element.variantsDimensionsPaperType]} <br/>
                {props.element.variantsWidth}{componentConstants.language.LANG_UNIT_OF_MEASURE} x {props.element.variantsHeight}{componentConstants.language.LANG_UNIT_OF_MEASURE}
            </TableCell>
            <TableCell>
                {parseInt(props.element.ordersItemsQuantity) !== parseInt(quantity) ?
                      <Button className={classes.button} color="primary" onClick={putOrderItemAction} fullWidth component="div" variant="contained">
                        {componentConstants.language.LANG_UPDATE_ORDERS_ITEMS_QUANTITY}
                    </Button>
                    :
                    null
                  }
                  {
                    confirmDelete ?
                    <React.Fragment>
                        <Button className={classes.button} color="primary" onClick={deleteOrdersItemsAction} fullWidth component="div" variant="contained">
                            {componentConstants.language.LANG_CONFIRM_DELETE}
                        </Button>
                        <Button className={classes.button} color="secondary" onClick={(e) => {setConmfirmDelete(false)}} fullWidth component="div" variant="outlined">
                            {componentConstants.language.LANG_CANCEL}
                        </Button>                        
                    </React.Fragment>
                    :
                    <IconButton color="secondary" component="span" onClick={() => {setConmfirmDelete(true) }}>
                        <DeleteIcon />
                    </IconButton>  
                  }
                  
            </TableCell>            
            </TableRow>

    );
}